<template>
  <div class="rs-builder" v-resize="onResize">
    <v-card
      v-if="showMessage && !loading"
      outlined
      max-width="600"
      elevation="12"
      class="mx-auto py-8"
    >
      <v-card-title v-if="messageTitle" class="text-h5 justify-center">
        {{ messageTitle }}</v-card-title
      >
      <v-card-text class="text-h6 text-center">
        {{ message }}
      </v-card-text>
      <v-card-text class="text-center">
        <router-link to="/admin/report-templates"
          >Back to Report Templates</router-link
        >
      </v-card-text>
    </v-card>

    <template v-else>
      <v-banner
        app
        sticky
        elevation="8"
        color="white"
        style="z-index: 6"
        class="mx-n8 mt-n4 px-4"
      >
        <v-row class="align-center">
          <v-col class="d-flex align-center col-12 col-md-6">
            <div class="d-flex align-center mr-8">
              <div>
                <h1 class="text-h6">Score Report Template Builder</h1>
                <div v-if="reportTemplate">
                  Editing template: <strong>{{ reportTemplate.name }}</strong>
                </div>
              </div>

              <v-btn
                :loading="loading"
                :disabled="loading"
                color="error"
                outlined
                class="ml-8"
                @click="showConfirmDialog = true"
              >
                Delete
              </v-btn>
            </div>
          </v-col>
          <v-spacer class="d-none d-md-block"></v-spacer>
          <v-col class="col-12 col-md-6 d-flex justify-md-end">
            <v-btn
              :disabled="loading"
              @click="variablesDialog = true"
              color="primary"
              class="mr-4"
            >
              Variables List
            </v-btn>
            <v-btn
              :loading="loading"
              :disabled="loading"
              @click="handleSaveTemplate"
              color="primary"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-banner>

      <!-- <div v-if="reportTemplate" class="mt-6 mb-4 d-flex align-center">
        <v-row>
          <v-col class="col-12 col-md-2">&nbsp; </v-col>
          <v-col>
            <v-tabs v-model="currentGeneration">
              <v-tab v-for="g in generations" :key="g">
                {{ g }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </div> -->

      <div class="mt-8">
        <v-row>
          <v-col class="col-12 col-md-2">
            <draggable
              style="position: sticky; top: 148px"
              class="rs-builder__sections"
              :list="sectionsList"
              :group="{ name: 'templateSections', pull: 'clone', put: false }"
              :clone="cloneSection"
              :sort="false"
              @change="log"
            >
              <div
                class="rs-builder__section"
                v-for="element in sectionsList"
                :key="element.id"
              >
                {{ element.name }}
                <div v-if="!element.isEditable">
                  <small class="grey--text">Not Editable</small>
                </div>
              </div>
            </draggable>
          </v-col>
          <v-col class="">
            <div
              class="rs-report rs-builder__report white--text fill-height pt-4"
            >
              <ReportTemplateHeader :isEditing="true"></ReportTemplateHeader>

              <div v-if="templateContentList" class="rs-report__content">
                <draggable
                  class="rs-builder__layout"
                  :list="templateContentList"
                  handle=".rs-builder__handle"
                  ghost-class="rs-builder__ghost"
                  drag-class="rs-builder__dragee"
                  chosen-class="rs-builder__dragee"
                  group="templateSections"
                  @change="log"
                >
                  <section
                    v-for="element in templateContentList"
                    :key="element.id"
                  >
                    <v-hover v-slot="{ hover }">
                      <div class="rs-builder__layout-item">
                        <span
                          class="
                            rs-builder__layout-tag rs-builder__handle
                            primary
                          "
                          ><v-icon color="white">mdi-drag</v-icon
                          >{{ element.name }}</span
                        >
                        <v-btn
                          v-if="hover"
                          color="error"
                          fab
                          x-small
                          dark
                          class="rs-builder__close-btn"
                          @click="handleDeleteSection(element.id)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>

                        <component
                          :is="element.component"
                          :isEditing="true"
                          :isMobile="isMobile"
                          :value.sync="element.value"
                        />
                      </div>
                    </v-hover>
                  </section>

                  <!-- make sure static elements go AFTER the list or drag sort will break-->
                  <div
                    v-if="templateContentList.length === 0"
                    class="flex align-center justify-center"
                  >
                    <h2 class="white--text">Drag Sections Here</h2>
                  </div>
                  <!-- <div slot="header" class="">
                  
                  </div> -->
                </draggable>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- <pre>{{ templateContentList }}</pre> -->

      <v-dialog
        v-if="variablesDialog"
        v-model="variablesDialog"
        max-width="800px"
      >
        <v-card tile>
          <v-card-title class="mb-6 text-h6"
            >Report Example Variables</v-card-title
          >
          <v-card-text>
            <p>
              Use these variables surrounded by double curly braces:
              <code v-pre>{{ variable_name }}</code> which will be replaced by
              the dynamic value when the report is generated.
            </p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Variable</th>
                    <th class="text-left">Example</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in reportMetaVars" :key="item.variable">
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <code
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              handleMetaCopy(formatMetaVar(item.variable))
                            "
                            >{{ formatMetaVar(item.variable) }}</code
                          >
                        </template>
                        <span>{{ metaVarTooltip }}</span>
                      </v-tooltip>
                    </td>
                    <td>{{ item.example }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn text @click="variablesDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- DELETE DIALOG -->
      <v-dialog v-model="showConfirmDialog" persistent max-width="500">
        <v-card>
          <v-overlay
            :value="loading"
            color="#091437"
            opacity="0.85"
            absolute="absolute"
            class="text-center"
          >
            <h3 class="mb-4">Deleting Score Report Template...</h3>
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
          </v-overlay>

          <v-card-title class="mb-4 text-h5 white--text error">
            Are you sure?
          </v-card-title>

          <v-card-text>
            <p>The Score Report Template will be permanently deleted.</p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="showConfirmDialog = false">
              Cancel
            </v-btn>

            <v-btn color="primary" text @click="handleConfirmDelete">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="showSnackBar"
        :timeout="2000"
        :color="snackBarColor"
        :elevation="24"
        transition="slide-x-reverse-transition"
        bottom
        right
        tile
      >
        <div class="text-center">{{ snackBarText }}</div>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
import { debounce as _debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import {
  updateReportTemplate,
  deleteReportTemplate,
} from "@/graphql/mutations";
import { getReportTemplate } from "@/graphql/queries";
import linkBuilderMixin from "@/mixins/linkBuilderMixin";
import draggable from "vuedraggable";

import ReportTemplateHeader from "@/components/ReportTemplateHeader.vue";

const initialArrayValueItems = function () {
  return [
    { id: uuidv4(), title: "Title", text: "<p>Text</p>" },
    { id: uuidv4(), title: "Title", text: "<p>Text</p>" },
    { id: uuidv4(), title: "Title", text: "<p>Text</p>" },
  ];
};

export default {
  mixins: [linkBuilderMixin],
  components: {
    draggable,
    ReportTemplateHeader,
  },
  props: ["templateId"],
  data() {
    return {
      loading: false,
      isMobile: false,
      reportTemplate: null,
      showMessage: false,
      messageTitle: "",
      message: "",
      variablesDialog: false,
      reportMetaVars: null,
      metaVarTooltip: "Click to copy",
      showSnackBar: false,
      snackBarColor: "info",
      snackBarText: "",
      currentGeneration: 0,
      showConfirmDialog: false,
      generations: [
        "Generation X",
        "Baby Boomer",
        "Millennials",
        "Silent Generation",
      ],
      // section component names must match a component in /components/reportTemplateBuilder/sections
      sectionsList: [
        {
          id: uuidv4(),
          name: "Header with Divider",
          componentName: "SectionTextBlockDivider",
          isEditable: true,
          value:
            '<h2 class="text-h5 text-md-h4 text-lg-h2"><strong>Header Text Block with Divider</strong></h2>',
        },
        {
          id: uuidv4(),
          name: "Text Block",
          componentName: "SectionTextBlock",
          isEditable: true,
          value: "<p>Generic Text Block</p>",
        },
        {
          id: uuidv4(),
          name: "Three Column Cards",
          componentName: "SectionThreeColumn",
          isEditable: true,
          type: Array,
          value: [],
        },
        {
          id: uuidv4(),
          name: "Score Compare Graphic",
          componentName: "SectionScoreCompareGraphic",
          isEditable: false,
          value: {},
        },
        {
          id: uuidv4(),
          name: "Score Improve Graphic",
          componentName: "SectionScoreImproveGraphic",
          isEditable: true,
          value: {},
        },
        {
          id: uuidv4(),
          name: "How Much Lose Graphic",
          componentName: "SectionScoreHowMuchLoseGraphic",
          isEditable: true,
          value: {},
        },
        {
          id: uuidv4(),
          name: "CTA Button",
          componentName: "SectionCtaButton",
          isEditable: true,
          value: "<p>CTA BUTTON</p>",
        },
        {
          id: uuidv4(),
          name: "Section Divider Line",
          componentName: "SectionDivider",
          isEditable: false,
          value: null,
        },
        {
          id: uuidv4(),
          name: "Video Embed",
          componentName: "SectionVideo",
          isEditable: false,
          value: "https://www.youtube.com/watch?v=Iva3ohTCor8",
        },
        {
          id: uuidv4(),
          name: "Section Spacer",
          componentName: "SectionSpacer",
          isEditable: false,
          value: null,
        },
      ],
      templateContentList: [],
    };
  },

  mounted() {
    this.loading = true;
    // Get ID from route props
    // If prop templateId, check it is a valid UUID
    console.log("templateID", this.templateId);
    if (this.templateId) {
      const regex = new RegExp(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
      );

      const regexValid = regex.test(this.templateId);
      console.log("regexValid", regexValid);
      if (regexValid) {
        // if uuid is valid, fetch and load the template
        // get variables list
        this.handleReportMeta();

        this.handleGetReportTemplate(this.templateId);
      }
    } else {
      // Can't build without a templateId
      this.message = "Sorry, the requested report template can not be found.";
      this.showMessage = true;
      this.loading = false;
    }
  },
  methods: {
    log: function (evt) {
      window.console.log(evt);
    },
    onResize: _debounce(async function () {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }, 500),
    async handleReportMeta() {
      const reportMeta = await this.getReportMeta();
      const newArray = [];

      for (const [key, value] of Object.entries(reportMeta.variables)) {
        const newObj = {};

        newObj.variable = key;
        newObj.example = value;

        newArray.push(newObj);
      }

      this.reportMetaVars = newArray;
    },
    formatMetaVar(variable) {
      return `{{${variable}}}`;
    },
    async handleMetaCopy(variable) {
      const copied = await this.genericCopy(variable);

      if (copied) {
        this.metaVarTooltip = "Copied to clipboard!";
      }

      setTimeout(() => {
        this.metaVarTooltip = "Click to copy";
      }, 500);
    },
    async getReportMeta() {
      try {
        const response = await fetch(
          process.env.VUE_APP_SCORE_API + "score_report_meta_v3",
          {
            method: "POST",
            headers: {
              // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const results = await response.json();
          if (results.success) {
            return results;
          }
        } else {
          throw new Error("Something failed fetching meta.");
        }
      } catch (error) {
        console.log("Score fetch error: ", error);
      }
    },
    async handleGetReportTemplate(templateId) {
      // Get all report templates to set in select
      try {
        API.graphql({
          query: getReportTemplate,
          variables: { id: templateId },
        }).then((res) => {
          if (!res.data.getReportTemplate) {
            this.message =
              "Sorry, the requested report template can not be found.";
            this.showMessage = true;
            this.loading = false;
            return;
          } else {
            // load the report template in builder
            this.reportTemplate = res.data.getReportTemplate;
            console.log("Report Template Found: ", this.reportTemplate);

            const template = JSON.parse(this.reportTemplate.template);
            console.log("PARSED TEMPLATE", template);
            template[0].content.forEach((section) => {
              section.component = () =>
                import(
                  `@/components/reportTemplateBuilder/sections/${section.componentName}.vue`
                );
            });
            this.templateContentList = template[0].content;
          }
        });
      } catch (error) {
        this.showSnackBar = true;
        (this.snackBarText = "Error retrieving requested report template: "),
          error;
        this.snackBarColor = "error";
      } finally {
        this.loading = false;
      }
    },
    cloneSection(section) {
      const newSection = Object.assign({}, section);

      return {
        id: uuidv4(),
        name: newSection.name,
        isEditable: newSection.isEditable,
        componentName: newSection.componentName,
        value:
          newSection.type === Array
            ? new initialArrayValueItems()
            : newSection.value,
        component: () =>
          import(
            `@/components/reportTemplateBuilder/sections/${newSection.componentName}.vue`
          ),
      };
    },
    handleImportSectionComponent(componentName) {
      return () =>
        import(
          `@/components/reportTemplateBuilder/sections/${componentName}.vue`
        );
    },
    handleDeleteSection(sectionId) {
      const sectionIndex = this.templateContentList.findIndex(
        (obj) => obj.id === sectionId
      );

      if (sectionIndex > -1) {
        this.templateContentList.splice(sectionIndex, 1);
      }
    },
    async handleSaveTemplate() {
      this.loading = true;

      const template = [
        {
          generation: "Baby Boomer",
          content: this.templateContentList,
        },
      ];

      //   const stringifiedTemplate = JSON.stringify(template);
      //   this.reportTemplate.template = stringifiedTemplate;

      let updateData = Object.assign({}, this.reportTemplate);
      updateData.template = JSON.stringify(template);

      delete updateData.__typename;
      delete updateData.createdAt;
      delete updateData.updatedAt;

      console.log("SAVE TEMPLATE", this.reportTemplate);
      try {
        const response = await API.graphql(
          graphqlOperation(updateReportTemplate, {
            input: updateData,
          })
        );
        const result = response.data.updateReportTemplate;
        result.template = JSON.parse(result.template);
        console.log("Saved Result", result);

        this.showSnackBar = true;
        this.snackBarText = "Template saved!";
        this.snackBarColor = "success";
      } catch (error) {
        console.log("Error updating report template: ", error);
        this.showSnackBar = true;
        this.snackBarText = "Error saving template";
        this.snackBarColor = "error";
      } finally {
        this.loading = false;
        // this.showGraphicEditorDialog = false;

        // this.$nextTick(() => {
        //   this.hasGraphicEditorData = false;
        // });
      }
    },
    async handleConfirmDelete() {
      this.loading = true;

      console.log("DELETE TEMPLATE", this.reportTemplate.id);

      try {
        const response = await API.graphql(
          graphqlOperation(deleteReportTemplate, {
            input: { id: this.reportTemplate.id },
          })
        );
        const result = response.data.deleteReportTemplate;
        result.template = JSON.parse(result.template);
        console.log("Saved Result", result);
        this.$router.push({ path: "/admin/report-templates" });
        // this.showSnackBar = true;
        // this.snackBarText = "Template DELETED!";
        // this.snackBarColor = "success";
      } catch (error) {
        console.log("Error deleting report template: ", error);
        this.showSnackBar = true;
        this.snackBarText = "Error deleting template";
        this.snackBarColor = "error";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/report";
.rs-builder {
  &__report {
    background: linear-gradient(#18466f, #316eae);
  }
  &__sections {
    border: 1px solid lightgray;
  }

  &__section {
    border-bottom: 1px solid lightgray;
    padding: 1rem;
    background-color: whitesmoke;
    font-size: 0.875rem;
    cursor: grab;

    &.sortable-chosen {
      cursor: grabbing;
    }
  }

  &__layout {
    /* border: 1px solid lightgray; */
    padding: 0.5rem 1rem;
  }

  &__layout-tag {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.75rem;
    padding: 0.125rem 0.25rem;
  }

  &__layout-item {
    position: relative;
    border: 1px solid rgb(255, 255, 255, 0.2);
    background-color: rgba(white, 0.05);
    padding-top: 3rem;
    padding: 3rem 1rem 0;
    margin: 1rem auto;
  }

  &__handle {
    cursor: move;

    &:hover {
      opacity: 0.8;
    }
  }

  &__close-btn {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 1;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__ghost {
    opacity: 0.5;
    transition: all 0.15s linear;
  }

  &__dragee {
    background-color: #85b4e7;
    transition: all 0.15s linear;
  }

  ::v-deep .ProseMirror {
    outline: 2px dashed cyan;
    background-color: rgba(cyan, 0.067);
    transition: all 0.15s linear;

    &-focused {
      background-color: rgba(magenta, 0.08);
      outline: 2px dashed magenta;
      box-shadow: 0 3px 40px rgba(magenta, 0.593);
    }
  }
}
</style>

<style lang="scss">
// UnScoped

// Non-ProseMirror editing element
.rs-report-section-edit {
  position: relative;
  outline: 2px dashed cyan;
  background: rgba(cyan, 0.2);
  transition: all 0.15s linear;
  user-select: none;
  color: inherit;
  font-size: inherit;
  white-space: normal;

  .v-btn {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    outline: 2px dashed magenta;
    background: rgba(magenta, 0.2);
    box-shadow: 0 3px 40px rgba(magenta, 0.593);
  }
}
</style>
